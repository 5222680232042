@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  @apply font-montserrat;
}

body,
p,
span,
a,
div {
  @apply font-lora;
}
/*  */

/* Global CSS Variables */
:root {
  --scrollbar-thumb: #bce3b3; /* Light Green (Scrollbar Thumb) */
  --scrollbar-track: #162c18; /* Deep Forest Green (Scrollbar Track) */
}

/* Ensure Scrollbar is visible */
body {
  overflow: auto; /* Make sure scrollbars appear */
  overflow-x: hidden;
}

/* Webkit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Scrollbar width */
  height: 12px; /* Horizontal scrollbar height */
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track); /* Scrollbar Track */
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb); /* Scrollbar Thumb */
  border-radius: 10px; /* Rounded corners */
  border: 3px solid var(--scrollbar-track); /* Border to match the track */
  transition: background-color 0.3s ease; /* Smooth transition */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a2c8a2; /* Lighter color on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: #8bbf8b; /* Slightly darker color when active */
}

/* Ensure Scrollbar is always visible in Chrome and Safari */
::-webkit-scrollbar-thumb {
  min-height: 30px; /* Minimum height for vertical scrollbar */
  min-width: 30px; /* Minimum width for horizontal scrollbar */
}

/* Apply scrollbar styles globally */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
  border: 3px solid var(--scrollbar-track);
}

/* Fixing scrollbar thumb behavior for Chrome on hover */
::-webkit-scrollbar-thumb {
  -webkit-transition: background-color 0.3s ease !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a2c8a2 !important; /* Lighter color on hover */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  display: flex;
  width: max-content;
  animation: scroll 102s linear infinite;
}

input:-webkit-autofill,
textarea:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px #bce3b3 inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #bce3b3 inset !important;
  border: 2px solid rgba(88, 165, 98, 1) !important;
  border-radius: 8px;
  -webkit-text-fill-color: #2d572c;
}
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: #2d572c;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #2d572c;
}
.hide-header header {
  display: none;
}
.hide-footer footer {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-out forwards;
}
